import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/src/components/layout.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1>{`Integrating Polis into Decisionmaking Processes`}</h1>
    <ul>
      <li parentName="ul">{``}<a parentName="li" {...{
          "href": "/Polis",
          "title": "Polis"
        }}>{`Polis`}</a>{` itself is not a governance process, it is a `}<a parentName="li" {...{
          "href": "/tool",
          "title": "tool"
        }}>{`tool`}</a>{` for gathering input which could be used in various processes benefiting from increased coherence, including but not limited to:`}
        <ul parentName="li">
          <li parentName="ul">{`governance and policy creation processes`}</li>
          <li parentName="ul">{`problem definition`}</li>
          <li parentName="ul">{`strategy development`}</li>
        </ul>
      </li>
      <li parentName="ul">{`See: `}<a parentName="li" {...{
          "href": "/facilitation",
          "title": "facilitation"
        }}>{`facilitation`}</a>{``}</li>
    </ul>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      